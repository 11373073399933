import React from "react";
import type { SxProps } from "@mui/material";
import { styled } from "@mui/material";
import type { ChangeHandler } from "react-hook-form";
import { themeTokens } from "components/Theme/theme";

const ButtonLikeLabel = styled("label")(
  `
  display: flex;
  box-sizing: border-box;
  height: 44px;
  border: 1px solid #449be1;
  padding: 8px 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  text-transform: none;
  background-color: #fff;
  color: #1a77ca;
`,
  ({ variant }: { variant: Variants }) =>
    variant === "secondary" &&
    `
      border: 1px solid ${themeTokens.color.button.border.secondary};
      color: ${themeTokens.color.button.text.secondary};
    `,
  ({ variant }: { variant: Variants }) =>
    variant === "tertiary" &&
    `
      border: 1px solid ${themeTokens.color.border.feature};
      color: ${themeTokens.color.text.feature};
    `
);

const HiddenRadio = styled("input")(
  (props) => `
  position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;

  :checked + label {
    border:  1px solid ${themeTokens.color.button.background.loud.default};
    color: ${themeTokens.color.button.text.loud};
    background-color: ${themeTokens.color.button.background.loud.default};
  }
`,
  ({ variant }: { variant: Variants }) =>
    variant === "secondary" &&
    `
    :checked + label {
      border: 1px solid ${themeTokens.color.button.border.secondary};
      color: ${themeTokens.color.button.text.secondary};
      background-color: ${themeTokens.color.button.background.secondary.pressed};
    }
    `,
  ({ variant }: { variant: Variants }) =>
    variant === "tertiary" &&
    `
    :checked + label {
      border: 1px solid ${themeTokens.color.border.feature};
      color: ${themeTokens.color.text.feature};
      background-color: #C5AEEEA3;
    }
    `
);

const Wrapper = styled("div")(
  ({ fullWidth }: { fullWidth?: boolean }) => `
  ${fullWidth && `flex: 1 0 0;`}
`
);

type Variants = "primary" | "secondary" | "tertiary";

type CheckoutButtonLikeRadioProps = {
  id: string;
  name: string;
  value: string | number;
  fullWidth?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | ChangeHandler;
  checked?: boolean;
  buttonSx?: SxProps;
  variant?: Variants;
} & React.PropsWithChildren;

export const CheckoutButtonLikeRadio = React.forwardRef(
  (props: CheckoutButtonLikeRadioProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { id, name, value, children, fullWidth, checked, onChange, buttonSx, variant = "primary" } = props;
    return (
      <Wrapper fullWidth={fullWidth}>
        <HiddenRadio
          type="radio"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          ref={ref}
          checked={checked}
          variant={variant}
        />
        <ButtonLikeLabel htmlFor={id} sx={buttonSx} variant={variant}>
          {children}
        </ButtonLikeLabel>
      </Wrapper>
    );
  }
);
