import React from "react";
import { styled } from "@mui/material";
import { CheckIconSimple } from "areas/checkout/components/CheckIconSimple";
import type { CheckoutStepNames } from "areas/purchasing/Checkout";

const StepsListContainer = styled("div")`
  display: flex;
  justify-content: center;
`;

const StepsList = styled("ol")`
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const StepLi = styled("li")`
  display: flex;
  margin-right: 12px;
`;

const StepItemInner = styled("div")`
  display: flex;
  align-content: center;
  height: 38px;
`;

const StyledStepNumber = styled("div")<{ isCurrent: boolean }>`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: 12px;
  width: 38px;
  height: 38px;
  border-radius: 38px;
  font-weight: 700;
  font-size: 15px;
  background: ${(props) => (props.isCurrent ? "#0D80D8" : "#F4F6F8")};
  color: ${(props) => (props.isCurrent ? "#fff" : "#657287")};
`;

const StyledStepComplete = styled("div")`
  box-sizing: border-box;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: 12px;
  width: 38px;
  height: 38px;
  border-radius: 38px;
  border: 1px solid #0d80d8;
  background: rgba(13, 128, 216, 0.1);
`;

const StepTitle = styled("p")<{ step: number; currentStep: number }>`
  margin: 0;
  align-self: center;
  font-size: 15px;
  font-weight: ${(props) => (props.currentStep === props.step ? "500" : "400")};
  color: ${(props) => (props.currentStep === props.step ? "#2e475d" : "#586978")};
`;

const StepNumber = ({ step, currentStep }: { step: number; currentStep: number }) => {
  const isCurrent = step === currentStep;
  const isComplete = currentStep > step;

  if (isComplete) {
    return (
      <StyledStepComplete>
        <CheckIconSimple fillColor="#0D80D8" sx={{ marginLeft: "1px" }} />
      </StyledStepComplete>
    );
  }
  return <StyledStepNumber isCurrent={isCurrent}>{step}</StyledStepNumber>;
};

const StepSeparator = styled("div")`
  margin-left: 30px;
  margin-right: 30px;
  width: 50px;
  height: 1px;
  border-top: 1px solid #e6e8ea;
  align-self: center;
`;

export interface CheckoutStep {
  number: number;
  title: string;
  name: keyof typeof CheckoutStepNames;
}

export const StepProgress = ({ currentStep, steps }: { currentStep: number; steps: CheckoutStep[] }) => {
  return (
    <StepsListContainer data-testid={"checkout-step-progress"}>
      <StepsList>
        {steps.map((step) => (
          <StepLi key={step.number}>
            <StepItemInner>
              <StepNumber step={step.number} currentStep={currentStep} />
              <StepTitle step={step.number} currentStep={currentStep}>
                {step.title}
              </StepTitle>
              {step.number !== steps.length && <StepSeparator />}
            </StepItemInner>
          </StepLi>
        ))}
      </StepsList>
    </StepsListContainer>
  );
};
