import React, { useState } from "react";
import { Box, Button, Stack, styled } from "@mui/material";
import { themeTokens } from "components/Theme/theme";
import { CheckoutTextInput } from "./CheckoutTextInput";

const StepperButton = styled(Button)`
  text-transform: none;
  border: 1px solid ${themeTokens.color.button.border.secondary};
  background: ${themeTokens.color.button.background.secondary.default};
  color: ${themeTokens.color.button.text.secondary};
  font-weight: 700;
  line-height: 24px;
  height: 36px;
  min-width: 39px;
  transition: none;

  &.MuiButton-root:hover {
    background-color: rgba(26, 119, 202, 0.16);
  }

  &.MuiButton-root:active {
    background-color: ${themeTokens.color.button.background.secondary.pressed};
  }

  &:disabled {
    background-color: ${themeTokens.color.button.background.disabled};
    color: ${themeTokens.color.button.text.disabled};
    border: none;
  }
  &.max {
    border: 1px solid ${themeTokens.color.border.feature};
    color: ${themeTokens.color.text.feature};
  }
  &.max.MuiButton-root:hover {
    background-color: #c5aeeea3;
  }

  &.max.MuiButton-root:active {
    background-color: rgba(197, 174, 238, 0.9);
  }
`;

const StyledTextInput = styled(CheckoutTextInput)`
  height: 36px;
  border-color: ${themeTokens.color.border.primary};
  input {
    text-align: center;
    color: ${themeTokens.color.text.secondary};
  }
  &.max {
    border-color: ${themeTokens.color.border.feature};
    background-color: #c5aeeea3;
    color: ${themeTokens.color.text.feature};
  }
  &.max.MuiInput-root.Mui-focused:not(.Mui-error) {
    border-color: ${themeTokens.color.border.feature};
  }
  &.max > .MuiInput-input {
    color: ${themeTokens.color.text.feature};
  }
`;

const Wrapper = styled("div")(
  ({ fullWidth }: { fullWidth?: boolean }) => `
  ${fullWidth && `flex: 1 0 0;`}
`
);

type CheckoutStepperInputProps = {
  id: string;
  name: string;
  value: string | number;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: number
  ) => void;
  min?: number;
  max?: number;
} & React.PropsWithChildren;

export const CheckoutStepperInput = React.forwardRef(
  (props: CheckoutStepperInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { id, value, onChange, min = 0, max = Infinity } = props;

    const int = parseInt(value.toString(), 10) || 0;
    const [val, setVal] = useState<number>(int);

    const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      const int = parseInt(e.target.value.toString(), 10) || 0;
      const newVal = Math.max(min, Math.min(max, int));
      setVal(newVal);
      onChange && onChange(e, newVal);
    };

    const increment: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      const int = parseInt(val.toString(), 10);
      const newVal = Math.min(max, int + 1);
      setVal(newVal);
      onChange && onChange(e, newVal);
    };

    const decrement: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      const int = parseInt(val.toString(), 10);
      const newVal = Math.max(min, int - 1);
      setVal(newVal);
      onChange && onChange(e, newVal);
    };

    return (
      <Wrapper>
        <Stack direction="row" spacing={0.5}>
          <StepperButton onClick={decrement} disableRipple disabled={val === min}>
            &minus;
          </StepperButton>
          <Box width={"70px"}>
            <StyledTextInput id={id} value={val} onChange={onChangeHandler} className={val === max ? "max" : ""} />
          </Box>
          <StepperButton
            onClick={increment}
            disableRipple
            disabled={val === max}
            className={val === max - 1 ? "max" : ""}
          >
            +
          </StepperButton>
        </Stack>
      </Wrapper>
    );
  }
);
