import React from "react";
import type { ReactNode } from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import { formatMoney } from "utils/formatters/MoneyFormatter";

type PricingCardPriceProps = {
  price: number | undefined;
  currency: string | ReactNode;
  pricePerLabel: string | ReactNode;
  priceIsMinimum?: boolean;
};

const VolumePricing = styled(Typography)`
  box-sizing: border-box;
  margin: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
  line-height: 1.2;
`;

const placeholder = <>&nbsp;</>;

export function PricingCardPrice(props: PricingCardPriceProps) {
  const { price, currency, pricePerLabel, priceIsMinimum } = props;

  if (typeof price === "undefined") {
    return (
      <Grid item xs={12}>
        <Box></Box>
      </Grid>
    );
  }

  if (price < 0) {
    return (
      <Grid item xs={12}>
        <Box>
          <Typography component="span" visibility="hidden">
            {placeholder}
          </Typography>
          <VolumePricing fontWeight="fontWeightRegular" fontSize="1rem">
            Contact sales for volume pricing
          </VolumePricing>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      {priceIsMinimum ? (
        <Typography component="span" fontWeight="fontWeightRegular" visibility="visible">
          from
        </Typography>
      ) : (
        <Typography component="span" visibility="hidden">
          {placeholder}
        </Typography>
      )}

      <Box sx={{ marginTop: "-8px", marginBottom: "4px" }}>
        <Typography component="span" fontWeight="fontWeightBold" fontSize="1.2rem">
          $
        </Typography>
        <Typography component="span" fontWeight="fontWeightBold" fontSize="1.75rem">
          {formatMoney(price, { removeCurrency: true })}
        </Typography>
        <Typography variant="subtitle1" component="span" fontWeight="fontWeightRegular">
          &nbsp;{currency}&nbsp;
        </Typography>
        <Typography variant="subtitle1" component="span" fontWeight="fontWeightRegular">
          {pricePerLabel}
        </Typography>
      </Box>
    </Grid>
  );
}
