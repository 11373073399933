import type { PropsWithChildren } from "react";
import { createContext, useContext, useState } from "react";
import React from "react";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import { noOp } from "utils/noOp";
import type { ValidTargetPlans } from "areas/purchasing/Checkout";
import type { CheckoutContactSalesFields } from "./CheckoutContactSalesDialog";
import { CheckoutContactSalesDialog } from "./CheckoutContactSalesDialog";

type CheckoutContactSalesDialogState = {
  showDialog: (defaultValues: Partial<CheckoutContactSalesFields>) => void;
  open: boolean;
  submittedData?: CheckoutContactSalesFields;
};

const CheckoutContactSalesDialogContext = createContext<CheckoutContactSalesDialogState>({
  showDialog: noOp,
  open: false,
});

export const useCheckoutContactSalesDialog = (): CheckoutContactSalesDialogState => {
  return useContext(CheckoutContactSalesDialogContext);
};

type CheckoutContactSalesDialogProviderProps = PropsWithChildren<{
  source: "Checkout";
  targetPlan: ValidTargetPlans;
  serial?: string;
}>;

export function CheckoutContactSalesDialogProvider(props: CheckoutContactSalesDialogProviderProps) {
  const { children, source, serial } = props;
  const { userInfo } = useUserContext();
  const [open, setOpen] = useState<boolean>(false);
  const [submittedData, setSubmittedData] = useState<CheckoutContactSalesFields | undefined>();

  const [defaults, setDefaults] = useState<Partial<CheckoutContactSalesFields> | undefined>({
    firstName: userInfo?.firstName,
    lastName: userInfo?.lastName,
    email: userInfo?.email,
  });

  const showDialog = (defaultValues: Partial<CheckoutContactSalesFields>) => {
    setDefaults({
      ...defaults,
      ...defaultValues,
    });
    setOpen(true);
  };

  const hideDialog = (data?: CheckoutContactSalesFields) => {
    setOpen(false);
    setSubmittedData(data);
  };

  return (
    <CheckoutContactSalesDialogContext.Provider value={{ showDialog, open, submittedData }}>
      {children}
      <CheckoutContactSalesDialog
        open={open}
        onClose={hideDialog}
        defaults={defaults}
        source={source}
        serial={serial}
      />
    </CheckoutContactSalesDialogContext.Provider>
  );
}
