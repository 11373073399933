import React from "react";
import { Divider, FormLabel, Stack } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import type { SxProps, Theme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import type { OrderPreview } from "client/api/PurchaseApi";
import { CheckoutPrimaryButton, CheckoutSecondaryButton } from "areas/checkout/components/CheckoutButtons";
import { CheckoutPlanConfigRadio } from "areas/checkout/components/CheckoutPlanConfigRadio";
import { CheckoutStepperInput } from "areas/checkout/components/CheckoutStepperInput";
import { CheckoutStepLayout } from "areas/purchasing/components/CheckoutStepLayout";

interface CheckoutPlanConfigurationStepProps {
  onBack: () => void;
  onChanged: (data: CheckoutPlanConfigurationInputs) => void;
  onSubmit: (data: CheckoutPlanConfigurationInputs) => void;
  orderSummary: React.ReactElement;
  sx?: SxProps<Theme>;
  orderPreview: OrderPreview | null;
  subscriptionType: "cloud" | "selfhosted";
  initialData: CheckoutPlanConfigurationInputs;
}

export enum SupportOptions {
  EightFive = "8/5",
  TwentyFourSeven = "24/7",
}

export enum ValidProjectValues {
  p20 = "20",
  p30 = "30",
  p40 = "40",
  p50 = "50",
  p60 = "60",
  p70 = "70",
  p80 = "80",
  p90 = "90",
  p100 = "100",
  p120 = ">120",
}

type BaseConfigurationInputs = {
  projects: ValidProjectValues;
  tenants: number;
  machines: number;
  support: SupportOptions;
};

type ServerConfigurationInputs = BaseConfigurationInputs;

type CloudConfigurationInputs = BaseConfigurationInputs & {
  tasks: "5" | "10" | "20" | ">40";
};

export type CheckoutPlanConfigurationInputs = CloudConfigurationInputs | ServerConfigurationInputs;

export function isCloudPlanConfig(config: CheckoutPlanConfigurationInputs): config is CloudConfigurationInputs {
  return config.hasOwnProperty("tasks");
}

export function CheckoutPlanConfigurationStep(props: CheckoutPlanConfigurationStepProps) {
  const { onBack, onSubmit, onChanged, orderSummary, sx, subscriptionType, initialData } = props;

  const stepTestId = "checkout-plan-configuration-step";

  const { handleSubmit, control, setValue, getValues } = useForm<CheckoutPlanConfigurationInputs>();

  return (
    <CheckoutStepLayout orderSummary={orderSummary} sx={sx} testId={stepTestId}>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <Typography variant="h5" fontWeight="600" marginBottom="24px">
          Upgrade your Octopus {subscriptionType === "cloud" ? "Cloud" : "Server"} plan
        </Typography>
        <Stack spacing={2} divider={<Divider sx={{ color: "#E6E8EA" }} />}>
          <Stack spacing={2}>
            <Typography variant="h6" fontWeight="700">
              Professional plan configuration
            </Typography>
            <FormLabel id="project-selection-label">
              <Typography fontWeight="700">Projects</Typography>
            </FormLabel>
            <Controller
              control={control}
              name="projects"
              defaultValue={initialData.projects}
              render={({ field }) => (
                <Stack
                  spacing={0.5}
                  direction={"row"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    const value = e.target.value as CheckoutPlanConfigurationInputs["projects"];
                    setValue(field.name, value);
                    onChanged(getValues());
                  }}
                >
                  <CheckoutPlanConfigRadio value={"20"} field={field}>
                    20
                  </CheckoutPlanConfigRadio>
                  <CheckoutPlanConfigRadio value={"30"} field={field}>
                    30
                  </CheckoutPlanConfigRadio>
                  <CheckoutPlanConfigRadio value={"40"} field={field}>
                    40
                  </CheckoutPlanConfigRadio>
                  <CheckoutPlanConfigRadio value={"50"} field={field}>
                    50
                  </CheckoutPlanConfigRadio>
                  <CheckoutPlanConfigRadio value={"60"} field={field}>
                    60
                  </CheckoutPlanConfigRadio>
                  <CheckoutPlanConfigRadio value={"70"} field={field}>
                    70
                  </CheckoutPlanConfigRadio>
                  <CheckoutPlanConfigRadio value={"80"} field={field}>
                    80
                  </CheckoutPlanConfigRadio>
                  <CheckoutPlanConfigRadio value={"90"} field={field}>
                    90
                  </CheckoutPlanConfigRadio>
                  <CheckoutPlanConfigRadio value={"100"} field={field}>
                    100
                  </CheckoutPlanConfigRadio>
                  <Divider orientation="vertical" flexItem sx={{ "&&": { ml: 1, mr: 0.5 } }} />
                  <CheckoutPlanConfigRadio value={">120"} field={field} variant={"tertiary"}>
                    120+
                  </CheckoutPlanConfigRadio>
                </Stack>
              )}
            ></Controller>
            <Typography>
              Projects represent each component you deploy (e.g. each application, service, API, or database). We offer
              projects in the Professional plan in groups of 10 at $96 per project. If you need more than 100 projects,
              please contact us.
            </Typography>
            <FormLabel id="concurrent-tasks-selection-label">
              <Typography fontWeight="700">Concurrent tasks</Typography>
            </FormLabel>
            {subscriptionType === "selfhosted" && (
              <Typography>
                Task caps on your server license are handled by your on-site server. We have organised some best
                practises on our{" "}
                <a href="https://octopus.com/docs/best-practices/self-hosted-octopus/installation-guidelines#compute-recommendations">
                  documentation
                </a>{" "}
                that help explain this.
              </Typography>
            )}
            {subscriptionType === "cloud" && isCloudPlanConfig(initialData) && (
              <>
                <Controller
                  control={control}
                  name="tasks"
                  defaultValue={initialData.tasks}
                  render={({ field }) => (
                    <Stack
                      spacing={0.5}
                      direction={"row"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        const value = e.target.value as CloudConfigurationInputs["tasks"];
                        setValue(field.name, value);
                        onChanged(getValues());
                      }}
                    >
                      <CheckoutPlanConfigRadio value={"5"} field={field}>
                        5
                      </CheckoutPlanConfigRadio>
                      <CheckoutPlanConfigRadio value={"10"} field={field}>
                        10
                      </CheckoutPlanConfigRadio>
                      <CheckoutPlanConfigRadio value={"20"} field={field}>
                        20
                      </CheckoutPlanConfigRadio>
                      <Divider orientation="vertical" flexItem sx={{ "&&": { ml: 1, mr: 0.5 } }} />
                      <CheckoutPlanConfigRadio value={">40"} field={field} variant={"tertiary"}>
                        40+
                      </CheckoutPlanConfigRadio>
                    </Stack>
                  )}
                ></Controller>
                <Typography>
                  The number of deployments, runbooks, and other jobs your Octopus instance can run at a time. We offer
                  task caps in groups of 5, 10, and 20. If you need more than 20 concurrent tasks, please contact us.
                </Typography>
              </>
            )}
            <FormLabel id="support-selection-label">
              <Typography fontWeight="700">Support</Typography>
            </FormLabel>
            <Controller
              control={control}
              name="support"
              defaultValue={initialData.support}
              render={({ field }) => (
                <Stack
                  spacing={0.5}
                  direction={"row"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    const value = e.target.value as SupportOptions;
                    setValue(field.name, value);
                    onChanged(getValues());
                  }}
                >
                  <CheckoutPlanConfigRadio value={SupportOptions.EightFive} field={field}>
                    9am&ndash;5pm Weekdays
                  </CheckoutPlanConfigRadio>
                  <Divider orientation="vertical" flexItem sx={{ "&&": { ml: 1, mr: 0.5 } }} />
                  <CheckoutPlanConfigRadio value={SupportOptions.TwentyFourSeven} field={field} variant={"tertiary"}>
                    24/7
                  </CheckoutPlanConfigRadio>
                </Stack>
              )}
            ></Controller>
            <Typography>
              We offer email support 9-5pm weekdays on the Professional plan. If you need 24/7 support, please contact
              us.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h6" fontWeight="700">
              Add-ons
            </Typography>
            <FormLabel id="tenants-selection-label">
              <Typography fontWeight="700">Tenants</Typography>
            </FormLabel>
            <Controller
              control={control}
              name="tenants"
              defaultValue={initialData.tenants}
              render={({ field }) => (
                <CheckoutStepperInput
                  {...field}
                  id="tenants"
                  onChange={(_e, value) => {
                    setValue(field.name, value);
                    onChanged(getValues());
                  }}
                  max={101}
                />
              )}
            />
            <Typography>
              Tenants let you model multiple customers, instances, or locations to account for their unique needs,
              without duplicating project configuration. If you need more than 100 tenants, please contact us.
            </Typography>
            <FormLabel id="machines-selection-label">
              <Typography fontWeight="700">Machines</Typography>
            </FormLabel>
            <Controller
              control={control}
              name="machines"
              defaultValue={initialData.machines}
              render={({ field }) => (
                <CheckoutStepperInput
                  {...field}
                  id="machines"
                  onChange={(_e, value) => {
                    setValue(field.name, value);
                    onChanged(getValues());
                  }}
                  max={101}
                />
              )}
            />
            <Typography>
              A machine is a Windows, Linux, or MacOS application host registered as a deployment target with Octopus.
              If you need more than 100 machines, please contact us.
            </Typography>
          </Stack>
        </Stack>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ "&&": { marginTop: "auto" } }}>
          <Grid item md={6} marginTop={"32px"}>
            <CheckoutSecondaryButton disableElevation variant="contained" onClick={onBack}>
              Return to select plan
            </CheckoutSecondaryButton>
          </Grid>
          <Grid item md={6} display={"flex"} justifyContent={"flex-end"} marginTop={"32px"}>
            <CheckoutPrimaryButton disableElevation variant="contained" type="submit">
              Continue to payment
            </CheckoutPrimaryButton>
          </Grid>
        </Grid>
      </form>
    </CheckoutStepLayout>
  );
}
