import React, { useState } from "react";
import type { SelectChangeEvent } from "@mui/material";
import { Box, Grid, List, ListItem, Typography, Link } from "@mui/material";
import type { DateTime } from "luxon";
import { useFeatureFlag } from "contexts/ConfigurationProvider/ConfigurationProvider";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { ExternalUriMap } from "utils/ExternalUriMap";
import { IsServerLicenseStripeSubscriptionLicensingChannel } from "utils/ServerLicenseLicensingChannelTypeHelpers";
import type { ServerLicenseDto } from "client/api/ServerLicenseApi";
import { UpgradePlan } from "client/api/UpgradePlan";
import { PricingCardCurrentPlanChip } from "components/PricingCard/PricingCardCurrentPlanChip";
import {
  FeatureItemDivider,
  FeatureListDivider,
  FeatureListItemText,
} from "components/PricingCard/PricingCardFeatureListElements";
import { PricingCardFeatureListItem } from "components/PricingCard/PricingCardFeatureListItem";
import { PricingCardPriceSelector } from "components/PricingCard/PricingCardPriceSelector";
import { PricingCardTitle } from "components/PricingCard/PricingCardTitle";
import type { CatalogProduct } from "areas/serverLicenses/detail/ServerLicenseUpgrade";
import { PlanTestId } from "areas/serverLicenses/detail/ServerLicenseUpgrade";
import { PricingCardAction, getUpgradeAction } from "./ServerPricingCardAction";

const PricingCardPriceCode = UpgradePlan.Server_Annually_Professional;
const PlanName = "Professional";
const planStyles = {
  borderRadius: "10px",
  background: "#FFF",
  border: "1px solid #D4DCE3",
};
const currentPlanStyles = { borderRadius: "10px", background: "#FFF", border: "1px solid #D4DCE3" };

export function ServerProfessionalPricingCard({ catalog }: { catalog: CatalogProduct }) {
  const { entity: serverLicense } = useEntity<ServerLicenseDto>();
  const [selectedProjects, setSelectedProjects] = useState(catalog?.prices[0]?.projectQuantity || "");
  const useZuoraProfessionalCheckout = useFeatureFlag("ZuoraProfessionalCheckout");
  const useZuoraCheckout = useFeatureFlag("ZuoraCheckout");

  if (!serverLicense) return <></>;

  const upgradeAction = getUpgradeAction(PricingCardPriceCode, serverLicense.licensingChannel);
  const isCurrentPlan = upgradeAction === "no-change";

  const changedProductSelectionHandler = (e: SelectChangeEvent<unknown>) => {
    if (typeof e.target.value === "string" || typeof e.target.value === "number") {
      setSelectedProjects(e.target.value.toString());
    }
  };

  let nextRenewalDate: DateTime | undefined = undefined;
  if (IsServerLicenseStripeSubscriptionLicensingChannel(serverLicense.licensingChannel)) {
    nextRenewalDate = serverLicense.licensingChannel?.nextRenewalDate;
  }

  const features = [
    [
      <FeatureListItemText>Best for under 100 projects</FeatureListItemText>,
      <FeatureListItemText>Unlimited users</FeatureListItemText>,
      <FeatureListItemText>
        Concurrent tasks&nbsp;<a href={ExternalUriMap.octopusComDocsInstallationGuidelinesPath}>best practices</a>
      </FeatureListItemText>,
      <FeatureListItemText>3 instances</FeatureListItemText>,
      <FeatureListItemText>2 high availability nodes</FeatureListItemText>,
    ],
    [
      <FeatureListItemText>Runbook automation</FeatureListItemText>,
      <FeatureListItemText>Environment progression</FeatureListItemText>,
      <FeatureListItemText>SSO and RBAC</FeatureListItemText>,
    ],
    [
      <FeatureListItemText>Onboarding with an expert guide</FeatureListItemText>,
      <FeatureListItemText>8/5 support (9-5pm weekdays)</FeatureListItemText>,
    ],
  ];

  return (
    <Grid
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={isCurrentPlan ? currentPlanStyles : planStyles}
      data-testid={PlanTestId.Professional}
    >
      <Grid container flexDirection="column" alignItems="stretch">
        <Grid
          container
          padding="1.3rem"
          sx={{ position: "relative" }}
          data-testid={`${PlanTestId.Professional}-header`}
        >
          <PricingCardCurrentPlanChip isCurrentPlan={isCurrentPlan} />
          <PricingCardTitle title={PlanName} />
          <PricingCardPriceSelector
            catalog={catalog}
            selectedProjects={selectedProjects}
            onChange={changedProductSelectionHandler}
            priceIsMinimum={true}
          />
          <Grid container>
            <Grid item xs={12}>
              <PricingCardAction
                serverLicenseId={serverLicense.id}
                action={upgradeAction}
                targetPlan={PricingCardPriceCode}
                targetPlanName={PlanName}
                nextRenewalDate={nextRenewalDate}
                projectQuantity={selectedProjects}
              />
            </Grid>
          </Grid>
          {useZuoraCheckout && useZuoraProfessionalCheckout && (
            <Grid item xs={12} sx={{ textAlign: "center", marginTop: "0.75rem" }}>
              <Link fontSize={"medium"} fontWeight={700} href={ExternalUriMap.octopusComContactUsPath}>
                Or Talk to us
              </Link>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <List disablePadding={true}>
            {features.map((list, listIndex) => (
              <ListItem key={`${listIndex}`} disablePadding sx={{ display: "block" }}>
                <List
                  sx={{
                    paddingTop: "0px",
                    paddingBottom: "4px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {listIndex < features.length && <FeatureListDivider />}
                  {listIndex === 0 && (
                    <>
                      <PricingCardFeatureListItem
                        feature={
                          <Typography variant="body1" flexGrow="1">
                            <Box
                              component="a"
                              href={ExternalUriMap.octopusComPricingFeaturesPath}
                              target="_blank"
                              fontWeight="fontWeightRegular"
                              sx={{ textDecoration: "underline", color: "#11181D" }}
                            >
                              All core features
                            </Box>
                          </Typography>
                        }
                      />
                      <FeatureItemDivider />
                    </>
                  )}
                  {list.map((feature, index) => (
                    <React.Fragment key={`${listIndex}-${index}`}>
                      <PricingCardFeatureListItem feature={feature} />
                      {index < list.length - 1 && <FeatureItemDivider />}
                    </React.Fragment>
                  ))}
                </List>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
}
