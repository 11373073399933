import type { AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import Environment from "environment";
import Cookies from "js-cookie";
import qs from "qs";
import { interceptAndDeserializeDateTimes } from "@cpt/shared";
import { navigateToAuth } from "utils/navigationHelpers";
import { BASE_URL as CSRF_BASE_URL, getCsrfToken } from "./api/CsrfApi";

const defaultAxiosConfig: AxiosRequestConfig = {
  baseURL: process.env.API_URL,
  paramsSerializer: (params) => {
    return qs.stringify(params, { allowDots: true });
  },
};

let isRedirecting = false;

export const unauthenticatedAxiosClient = axios.create(defaultAxiosConfig);
const axiosClient = axios.create({
  ...defaultAxiosConfig,
  withCredentials: true, // Note: auth token is sent in cookies
});

axiosClient.interceptors.request.use(
  async (req) => {
    // resolve query requests early
    const nonCsrfMethods = ["get", "head", "options", "trace"];
    if (req.method && nonCsrfMethods.includes(req.method.toLowerCase())) {
      return Promise.resolve(req);
    }

    // if we're already redirecting then bail out of making the request to allow the navigation to be processed more quickly
    if (isRedirecting) {
      return Promise.reject();
    }

    // for commands, check if we already have a csrf token, if not then get one before making the request
    const csrfToken = Cookies.get(Environment.csrfCookieName);
    if (!csrfToken && req.url !== CSRF_BASE_URL) {
      await getCsrfToken();
    }

    return Promise.resolve(req);
  },
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (req) => Promise.resolve(req),
  async (error) => {
    // if unauthenticated send user through auth flow
    if (error?.response?.status === 401) {
      isRedirecting = true;
      navigateToAuth();
    }

    throw error;
  }
);

axiosClient.interceptors.response.use((res: AxiosResponse) => {
  if (window.MiniProfiler) {
    const header = res.headers["x-miniprofiler-ids"];
    if (header) {
      const ids: string[] = JSON.parse(header);
      window.MiniProfiler.fetchResults(ids);
    }
  }

  return res;
});

axiosClient.interceptors.response.use(interceptAndDeserializeDateTimes);

export default axiosClient;
