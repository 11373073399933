import type { ComponentPropsWithRef } from "react";
import React from "react";
import { noOp } from "utils/noOp";
import { CheckoutButtonLikeRadio } from "./CheckoutButtonLikeRadio";

type CheckoutPlanConfigRadioProps = Omit<ComponentPropsWithRef<typeof CheckoutButtonLikeRadio>, "id" | "name"> & {
  id?: string;
} & {
  name?: string;
  field?: { name: string; value: string | undefined };
};

export const CheckoutPlanConfigRadio = React.forwardRef(
  (props: CheckoutPlanConfigRadioProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const buttonPadding = 6;
    const labelPadding = 4;
    const baseUnit = 8;
    const totalPadding = (buttonPadding + labelPadding) / baseUnit;

    const name = props.name || props.field?.name || "";
    const id = props.id || `${name}.${props.value}`;
    const checked = props.checked || props.field?.value === props.value;
    const variant = props.variant || "secondary";

    return (
      <CheckoutButtonLikeRadio
        buttonSx={{ minWidth: "39px", paddingLeft: totalPadding, paddingRight: totalPadding }}
        {...props}
        id={id}
        name={name}
        checked={checked}
        variant={variant}
        onChange={props.onChange || noOp}
      >
        {props.children}
      </CheckoutButtonLikeRadio>
    );
  }
);
