import type { ReactNode } from "react";
import React, { useState } from "react";
import { faCheckCircle as faCheckCircleRegular } from "@fortawesome/free-regular-svg-icons";
import type { SelectChangeEvent } from "@mui/material";
import { Box, Grid, Link, List, ListItem, Typography } from "@mui/material";
import type { DateTime } from "luxon";
import { FontAwesomeSvgIcon, InfoButton } from "@cpt/components";
import { useFeatureFlag } from "contexts/ConfigurationProvider/ConfigurationProvider";
import { useEntity } from "contexts/EntityProvider/EntityProvider";
import { IsStripeSubscriptionLicensingChannel } from "utils/CloudSubscriptionLicensingChannelTypeHelpers";
import { ExternalUriMap } from "utils/ExternalUriMap";
import type { CloudSubscriptionDto } from "client/api/CloudSubscriptionApi";
import { UpgradePlan } from "client/api/UpgradePlan";
import { useContactSalesDialog } from "components/Dialogs/ContactSalesDialog/ContactSalesDialogProvider";
import { LinkButton } from "components/LinkButton/LinkButton";
import { PopoverCloseButton } from "components/PopoverHelp/PopoverCloseButton";
import PopoverHelp from "components/PopoverHelp/PopoverHelp";
import { PricingCardCurrentPlanChip } from "components/PricingCard/PricingCardCurrentPlanChip";
import {
  FeatureItemDivider,
  FeatureListDivider,
  FeatureListItemText,
} from "components/PricingCard/PricingCardFeatureListElements";
import { PricingCardFeatureListItem } from "components/PricingCard/PricingCardFeatureListItem";
import { PricingCardPriceSelector } from "components/PricingCard/PricingCardPriceSelector";
import { PricingCardTitle } from "components/PricingCard/PricingCardTitle";
import type { CatalogProduct } from "areas/cloudSubscriptions/detail/CloudSubscriptionUpgrade";
import { PlanTestId } from "areas/cloudSubscriptions/detail/CloudSubscriptionUpgrade";
import { PricingCardAction, getUpgradeAction } from "./PricingCardAction";

const PricingCardPriceCode = UpgradePlan.Cloud_Annually_Enterprise;
const PlanName = "Enterprise";
const planStyles = {
  borderRadius: "10px",
  background: "#FFF",
  boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
};
const currentPlanStyles = { borderRadius: "10px", background: "#FFF", border: "1px solid #D4DCE3" };

export function EnterprisePricingCard({ catalog }: { catalog: CatalogProduct }) {
  const { entity: cloudSubscription } = useEntity<CloudSubscriptionDto>();
  const { showDialog } = useContactSalesDialog();
  const [selectedProjects, setSelectedProjects] = useState(catalog?.prices[0]?.projectQuantity || "");
  const useZuoraProfessionalCheckout = useFeatureFlag("ZuoraProfessionalCheckout");
  const useZuoraCheckout = useFeatureFlag("ZuoraCheckout");

  if (!cloudSubscription) return <></>;

  const upgradeAction = getUpgradeAction(PricingCardPriceCode, cloudSubscription.licensingChannel);
  const isCurrentPlan = upgradeAction === "no-change";

  let nextRenewalDate: DateTime | undefined = undefined;
  if (IsStripeSubscriptionLicensingChannel(cloudSubscription.licensingChannel)) {
    nextRenewalDate = cloudSubscription.licensingChannel?.nextRenewalDate;
  }

  const changedProductSelectionHandler = (e: SelectChangeEvent<unknown>) => {
    if (typeof e.target.value === "string" || typeof e.target.value === "number") {
      setSelectedProjects(e.target.value.toString());
    }
  };

  const features: (ReactNode | ReactNode[])[][] = [
    [
      <FeatureListItemText>Best for over 100 projects</FeatureListItemText>,
      <FeatureListItemText>Unlimited users</FeatureListItemText>,
      <FeatureListItemText as={"div"}>
        <Typography sx={{ textDecoration: "underline dashed 1px", textUnderlineOffset: "5px", fontSize: "0.9375rem" }}>
          Default of 20 concurrent tasks
        </Typography>
        <PopoverHelp
          trigger={"click"}
          placement={"bottom-end"}
          button={
            <InfoButton sx={{ marginLeft: ".5ch", marginTop: "2px", width: "18px", height: "18px", padding: "0px" }} />
          }
        >
          <Typography fontSize="14px">
            Max of 160 concurrent tasks for an{" "}
            <Link
              href={ExternalUriMap.octopusComPricingFaqCloudPricingPath}
              rel="noreferrer"
              fontSize={"1em"}
              fontWeight={400}
            >
              additional fee
            </Link>
            .
          </Typography>
        </PopoverHelp>
      </FeatureListItemText>,
      <FeatureListItemText>
        <LinkButton onClick={() => showDialog({ reason: "enterprise" })} fontSize={"1em"} fontWeight={400}>
          Contact Sales
        </LinkButton>
        &nbsp;for file storage
      </FeatureListItemText>,
      <FeatureListItemText>
        <LinkButton onClick={() => showDialog({ reason: "enterprise" })} fontSize={"1em"} fontWeight={400}>
          Contact Sales
        </LinkButton>
        &nbsp;for database storage
      </FeatureListItemText>,
    ],
    [
      <FeatureListItemText>Runbook automation</FeatureListItemText>,
      <FeatureListItemText>Environment progression</FeatureListItemText>,
      <FeatureListItemText>SSO and RBAC</FeatureListItemText>,
    ],
    [
      <FeatureListItemText>Tailored onboarding</FeatureListItemText>,
      <FeatureListItemText>24/7 support</FeatureListItemText>,
    ],
    [
      <FeatureListItemText as={"div"}>
        <Typography sx={{ textDecoration: "underline dashed 1px", textUnderlineOffset: "5px", fontSize: "0.9375rem" }}>
          Custom billing
        </Typography>
        <PopoverHelp
          trigger={"click"}
          placement={"bottom-end"}
          button={
            <InfoButton sx={{ marginLeft: ".5ch", marginTop: "2px", width: "18px", height: "18px", padding: "0px" }} />
          }
        >
          <Typography fontSize="14px">
            <PopoverCloseButton>
              <LinkButton onClick={() => showDialog({ reason: "enterprise" })} fontSize={"1em"} fontWeight={400}>
                Contact us
              </LinkButton>
            </PopoverCloseButton>{" "}
            or buy through{" "}
            <a href={ExternalUriMap.azureMarketPlaceOctopusDeployOverviewPath} target="_blank" rel="noreferrer">
              Azure Marketplace
            </a>
          </Typography>
        </PopoverHelp>
      </FeatureListItemText>,
      <FeatureListItemText as={"div"}>
        <Typography sx={{ textDecoration: "underline dashed 1px", textUnderlineOffset: "5px", fontSize: "0.9375rem" }}>
          Customer Success Manager
        </Typography>
        <PopoverHelp
          trigger={"click"}
          placement={"bottom-end"}
          button={
            <InfoButton sx={{ marginLeft: ".5ch", marginTop: "2px", width: "18px", height: "18px", padding: "0px" }} />
          }
        >
          <Typography fontSize="14px">Enterprise licenses &gt;$50k USD are assigned a CSM</Typography>
        </PopoverHelp>
      </FeatureListItemText>,
      <FeatureListItemText>Insights &amp; DORA metrics</FeatureListItemText>,
      <FeatureListItemText>SIEM audit log streaming</FeatureListItemText>,
      <FeatureListItemText>ITSM integration</FeatureListItemText>,
      [
        <FeatureListItemText>
          Add-on:&nbsp;<a href="#addon-priority-support">Priority support</a>
        </FeatureListItemText>,
        <FontAwesomeSvgIcon icon={faCheckCircleRegular} sx={{ color: "#0D80D8", fontSize: "24px" }} />,
      ],
      [
        <FeatureListItemText>
          Add-on:&nbsp;<a href="#addon-tam">TAM services</a>
        </FeatureListItemText>,
        <FontAwesomeSvgIcon icon={faCheckCircleRegular} sx={{ color: "#0D80D8", fontSize: "24px" }} />,
      ],
    ],
  ];

  return (
    <Grid
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={isCurrentPlan ? currentPlanStyles : planStyles}
      data-testid={PlanTestId.Enterprise}
    >
      <Grid container flexDirection="column" alignItems="stretch">
        <Grid container padding="1.3rem" sx={{ position: "relative" }} data-testid={`${PlanTestId.Enterprise}-header`}>
          <PricingCardCurrentPlanChip isCurrentPlan={isCurrentPlan} />
          <PricingCardTitle title={PlanName} />
          <PricingCardPriceSelector
            catalog={catalog}
            selectedProjects={selectedProjects}
            onChange={changedProductSelectionHandler}
            priceIsMinimum={true}
          />
          <Grid container>
            <Grid item xs={12}>
              <PricingCardAction
                cloudSubscriptionId={cloudSubscription.id}
                action={upgradeAction}
                targetPlan={UpgradePlan.Cloud_Annually_Enterprise}
                targetPlanName={PlanName}
                nextRenewalDate={nextRenewalDate}
                projectQuantity={selectedProjects}
              />
            </Grid>
          </Grid>
          {useZuoraCheckout && useZuoraProfessionalCheckout && (
            <Grid item xs={12} sx={{ textAlign: "center", marginTop: "0.75rem" }}>
              <Link
                sx={{
                  display: "block",
                  visibility: "hidden",
                }}
              >
                Placeholder text
              </Link>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <List disablePadding={true}>
            {features.map((list, listIndex) => (
              <ListItem key={`${listIndex}`} disablePadding sx={{ display: "block" }}>
                <List
                  sx={{
                    paddingTop: "0px",
                    paddingBottom: "4px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {listIndex < features.length && <FeatureListDivider />}
                  {listIndex === 0 && (
                    <>
                      <PricingCardFeatureListItem
                        feature={
                          <Typography variant="body1" flexGrow="1">
                            <Box
                              component="a"
                              href={ExternalUriMap.octopusComPricingFeaturesPath}
                              target="_blank"
                              fontWeight="fontWeightRegular"
                              sx={{ textDecoration: "underline", color: "#11181D" }}
                            >
                              All core features
                            </Box>
                          </Typography>
                        }
                      />
                      <FeatureItemDivider />
                    </>
                  )}
                  {list.map((item, index) => {
                    let icon = undefined,
                      feature = item;
                    if (Array.isArray(item)) {
                      feature = item[0];
                      icon = item[1];
                    }

                    return (
                      <React.Fragment key={`${listIndex}-${index}`}>
                        <PricingCardFeatureListItem feature={feature} icon={icon} />
                        {index < list.length - 1 && <FeatureItemDivider />}
                      </React.Fragment>
                    );
                  })}
                </List>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
}
