import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, debounce, Divider, styled } from "@mui/material";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { useUserContext } from "contexts/UserProvider/UserProvider";
import { logError } from "client/api/LoggingApi";
import { getOrderPreview, type OrderPreview, type OrderPreviewRequest, ProductType } from "client/api/PurchaseApi";
import { UpgradePlan } from "client/api/UpgradePlan";
import { MonitoredErrorBoundary } from "components/MonitoredErrorBoundary/MonitoredErrorBoundary";
import { CustomErrorAlert, ErrorMessageTryAgainOrContactSupport } from "components/alert/CustomErrorAlert";
import { NotFoundPage } from "pages/NotFoundPage";
import { isDropdownAutocompleteOption } from "areas/checkout/components/CheckoutDropdownInput";
import { CloudSubscriptionsRoutesMap } from "areas/cloudSubscriptions/CloudSubscriptionRoutesMap";
import { CheckoutComplete } from "areas/purchasing/CheckoutComplete";
import { CheckoutOrderSummary } from "areas/purchasing/components/CheckoutOrderSummary/CheckoutOrderSummary";
import { CheckoutPaymentStep } from "areas/purchasing/components/CheckoutPaymentStep";
import type {
  CheckoutCompanyForm,
  CheckoutIndividualForm,
  CheckoutPersonalDetailsInputs,
  PurchasingAs,
} from "areas/purchasing/components/CheckoutPersonalDetailsStep";
import { CheckoutPersonalDetailsStep } from "areas/purchasing/components/CheckoutPersonalDetailsStep";
import type { CheckoutPlanConfigurationInputs } from "areas/purchasing/components/CheckoutPlanConfigurationStep";
import { ValidProjectValues } from "areas/purchasing/components/CheckoutPlanConfigurationStep";
import {
  CheckoutPlanConfigurationStep,
  isCloudPlanConfig,
  SupportOptions,
} from "areas/purchasing/components/CheckoutPlanConfigurationStep";
import { ServerLicensesRoutesMap } from "areas/serverLicenses/ServerLicensesRoutesMap";
import { CheckoutLayout } from "./CheckoutLayout";
import { CheckoutContactSalesReasons, CheckoutContactSalesStep } from "./components/CheckoutContactSalesStep";
import type { CheckoutStep } from "./components/CheckoutStepProgress";
import { StepProgress } from "./components/CheckoutStepProgress";
import { useZuoraLibraryScriptLoader } from "./components/CheckoutZuoraIframe";

type SubscriptionType = "cloud" | "selfhosted";

const userInitiatedRetryAttemptLimit = 4;

export type ValidCloudPlans = UpgradePlan.Cloud_Annually_Starter | UpgradePlan.Cloud_Annually_Professional;
export type ValidServerPlans = UpgradePlan.Server_Annually_Starter | UpgradePlan.Server_Annually_Professional;
export type ValidTargetPlans = ValidCloudPlans | ValidServerPlans;

export interface OrderPreviewError {
  statusCode: number;
  message: string;
  canRetry: boolean;
  retry?: () => void;
}

export const CheckoutStepNames = {
  PlanConfiguration: "PlanConfiguration",
  PersonalDetails: "PersonalDetails",
  Payment: "Payment",
  ContactSales: "ContactSales",
} as const;

const StickySidebar = styled("div")`
  position: sticky;
  top: 0;
`;

const isCheckoutCompanyForm = (formData: CheckoutPersonalDetailsInputs): formData is CheckoutCompanyForm => {
  return formData.purchasingAs === "company";
};

const isCheckoutIndividualForm = (formData: CheckoutPersonalDetailsInputs): formData is CheckoutIndividualForm => {
  return formData.purchasingAs === "individual";
};

export function Checkout() {
  const [searchParams] = useSearchParams();
  const subscriptionId = searchParams.get("subscriptionId");
  const subscriptionType = searchParams.get("subscriptionType") ?? "";
  const projectQuantityString = searchParams.get("projects") ?? "";

  const isValidProjectValue = (value: string): value is ValidProjectValues => {
    return Object.values(ValidProjectValues).some((enumValue) => enumValue === value);
  };

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const plan = (searchParams.get("plan") as ValidTargetPlans) ?? "";
  const [currentStep, setCurrentStep] = useState(1);
  const [orderPreview, setOrderPreview] = useState<OrderPreview | null>(null);
  const [isLoadingOrderPreview, setIsLoadingOrderPreview] = useState<boolean>(false);
  const [isRecalculatingPrice, setIsRecalculatingBasePrice] = useState<boolean>(false);
  const [isTaxExempted, setTaxExempted] = useState(false);
  const { userInfo } = useUserContext();
  const getDefaultPlanConfigData = () => {
    const projects = isValidProjectValue(projectQuantityString) ? projectQuantityString : ValidProjectValues.p20;
    switch (plan) {
      case UpgradePlan.Cloud_Annually_Professional:
        return {
          projects: projects,
          tenants: 0,
          machines: 0,
          tasks: "5",
          support: SupportOptions.EightFive,
        } as const;
      case UpgradePlan.Server_Annually_Professional:
        return {
          projects: projects,
          tenants: 0,
          machines: 0,
          support: SupportOptions.EightFive,
        } as const;
      default:
        return undefined;
    }
  };
  const [planConfigData, setPlanConfigData] = useState<CheckoutPlanConfigurationInputs | undefined>(
    getDefaultPlanConfigData()
  );
  const [personalDetailsStepData, setPersonalDetailsData] = useState<CheckoutPersonalDetailsInputs>({
    billingContact: {
      firstName: userInfo?.firstName ?? "",
      lastName: userInfo?.lastName ?? "",
      email: userInfo?.email ?? "",
      streetAddress: "",
      country: null,
      city: "",
      state: "",
      postcode: "",
    },
    companyContact: {
      name: "",
      streetAddress: "",
      country: null,
      city: "",
      state: "",
      postcode: "",
    },
    taxId: "",
    purchasingAs: null,
  });
  const [orderPreviewError, setOrderPreviewError] = useState<OrderPreviewError | null>(null);
  const [userInitiatedRetryAttempt, setUserInitiatedRetryAttempt] = useState<number>(0);
  const { isZuoraLibraryReady, error: zuoraScriptLibraryError } = useZuoraLibraryScriptLoader();

  const billingProduct = (() => {
    switch (subscriptionType) {
      case "cloud":
        return plan === UpgradePlan.Cloud_Annually_Professional
          ? ProductType.ProfessionalCloud
          : ProductType.StarterCloud;
      case "selfhosted":
        return plan === UpgradePlan.Server_Annually_Professional
          ? ProductType.ProfessionalServer
          : ProductType.StarterServer;
      default:
        return ProductType.StarterServer;
    }
  })();

  const taxAddress = (() => {
    if (personalDetailsStepData.purchasingAs === null) {
      return null;
    }
    if (isCheckoutCompanyForm(personalDetailsStepData)) {
      return personalDetailsStepData.companyContact;
    }
    if (isCheckoutIndividualForm(personalDetailsStepData)) {
      return personalDetailsStepData.billingContact;
    }
    return null;
  })();

  const hasMinimumTaxInfo = !!(taxAddress && taxAddress.country && taxAddress.streetAddress);

  useDeepCompareEffectNoCheck(() => {
    // don't make unnecessary requests when purchasingAs is set and an address is not entered, note this still allows the initial order preview request to go ahead
    if (personalDetailsStepData.purchasingAs && !hasMinimumTaxInfo) {
      return;
    }

    setOrderPreviewError(null);
    setIsLoadingOrderPreview(true);

    const isCloudConfig = planConfigData && isCloudPlanConfig(planConfigData);
    const taskCap = isCloudConfig ? parseInt(planConfigData.tasks.replace(">", ""), 10) : undefined;

    const request: OrderPreviewRequest = {
      product: billingProduct,
      city: taxAddress?.city,
      county: "",
      country: taxAddress?.country?.value,
      postalCode: taxAddress?.postcode,
      state: taxAddress
        ? isDropdownAutocompleteOption(taxAddress.state)
          ? taxAddress.state.value
          : taxAddress.state ?? ""
        : "",
      pricingParameters: planConfigData && {
        projects: parseInt(planConfigData.projects.replace(">", ""), 10),
        tenants: planConfigData.tenants,
        machines: planConfigData.machines,
        taskCap: taskCap,
      },
    };

    const controller = new AbortController();

    const fetchData = async () => {
      const response = await getOrderPreview(request, controller.signal);
      setOrderPreview(response);
      setIsLoadingOrderPreview(false);
      setIsRecalculatingBasePrice(false);
    };

    const debouncedFetch = debounce(
      () =>
        fetchData().catch((e) => {
          if (!controller.signal.aborted) {
            setIsLoadingOrderPreview(false);
            setIsRecalculatingBasePrice(false);

            logError(
              `client received response with status code: ${e.response?.status} and message: ${e.message} when loading an order preview`
            );

            setOrderPreviewError({
              statusCode: e.response?.status,
              message: e.message,
              canRetry: userInitiatedRetryAttempt < userInitiatedRetryAttemptLimit,
              retry:
                userInitiatedRetryAttempt < userInitiatedRetryAttemptLimit
                  ? () => setUserInitiatedRetryAttempt(userInitiatedRetryAttempt + 1)
                  : undefined,
            });
          }
        }),
      850
    );

    debouncedFetch();

    return () => {
      setIsLoadingOrderPreview(false);
      controller.abort();
    };
  }, [
    taxAddress,
    taxAddress?.country?.value,
    taxAddress?.city,
    taxAddress?.state,
    taxAddress?.postcode,
    billingProduct,
    personalDetailsStepData.purchasingAs,
    hasMinimumTaxInfo,
    userInitiatedRetryAttempt,
    planConfigData,
  ]);

  const advanceStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const backStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const onSubmitPlanConfigurationData = (data: CheckoutPlanConfigurationInputs) => {
    advanceStep();
  };

  const onPlanConfigurationDataChanged = (data: CheckoutPlanConfigurationInputs) => {
    setPlanConfigData(data);
    setIsRecalculatingBasePrice(true);
  };

  const toggleTaxExempted = () => {
    setTaxExempted(!isTaxExempted);
  };

  const onSubmitPersonalDetails = (data: CheckoutPersonalDetailsInputs) => {
    setPersonalDetailsData(data);
    advanceStep();
  };

  const onAddressChanged = (data: CheckoutPersonalDetailsInputs) => {
    setPersonalDetailsData(data);
  };

  const onPurchasingAsChanged = (data: CheckoutPersonalDetailsInputs) => {
    setPersonalDetailsData(data);
  };

  if (!subscriptionId) {
    return <NotFoundPage />;
  }
  if (subscriptionType !== "cloud" && subscriptionType !== "selfhosted") {
    return <NotFoundPage />;
  }
  switch (subscriptionType) {
    case "cloud":
      if (plan !== UpgradePlan.Cloud_Annually_Professional && plan !== UpgradePlan.Cloud_Annually_Starter)
        return <NotFoundPage />;
      break;
    case "selfhosted":
      if (plan !== UpgradePlan.Server_Annually_Professional && plan !== UpgradePlan.Server_Annually_Starter)
        return <NotFoundPage />;
      break;
  }
  if (zuoraScriptLibraryError) {
    return <CustomErrorAlert message={<ErrorMessageTryAgainOrContactSupport />} />;
  }

  const steps: CheckoutStep[] = !isTaxExempted
    ? [
        { number: 1, title: "Information", name: CheckoutStepNames.PersonalDetails },
        { number: 2, title: "Payment", name: CheckoutStepNames.Payment },
      ]
    : [
        { number: 1, title: "Information", name: CheckoutStepNames.PersonalDetails },
        { number: 2, title: "Contact Sales", name: CheckoutStepNames.ContactSales },
      ];

  // Insert a new step if upgrading to a professional product
  if (plan === UpgradePlan.Server_Annually_Professional || plan === UpgradePlan.Cloud_Annually_Professional) {
    steps.forEach((step) => (step.number += 1));
    steps.unshift({ number: 1, title: "Configuration", name: CheckoutStepNames.PlanConfiguration });
  }

  return (
    <MonitoredErrorBoundary fallback={<CustomErrorAlert message={<ErrorMessageTryAgainOrContactSupport />} />}>
      <CheckoutLayout subscriptionId={subscriptionId} subscriptionType={subscriptionType} targetPlan={plan}>
        <Box sx={{ paddingTop: "16px", paddingBottom: "14px" }}>
          <StepProgress currentStep={currentStep} steps={steps} />
        </Box>
        <Divider sx={{ borderColor: "#E6E8EA" }} />

        <Steps
          checkoutSteps={steps}
          currentStep={currentStep}
          onSubmitPlanConfiguration={onSubmitPlanConfigurationData}
          onPlanConfigurationChanged={onPlanConfigurationDataChanged}
          onSubmitPersonalDetails={onSubmitPersonalDetails}
          onAddressChanged={onAddressChanged}
          onPurchasingAsChanged={onPurchasingAsChanged}
          planConfigurationData={planConfigData}
          personalDetailsStepData={personalDetailsStepData}
          orderPreview={orderPreview}
          orderPreviewError={orderPreviewError}
          orderSummary={
            <StickySidebar>
              <CheckoutOrderSummary
                country={taxAddress?.country ?? null}
                subscriptionType={subscriptionType}
                isTaxExempted={isTaxExempted}
                onToggleTaxExemption={toggleTaxExempted}
                orderPreview={orderPreview}
                errorReason={orderPreviewError}
                isLoadingOrderPreview={isLoadingOrderPreview}
                isRecalculatingBasePrice={isRecalculatingPrice}
                hasMinimumTaxInfo={hasMinimumTaxInfo}
              />
            </StickySidebar>
          }
          advanceStep={advanceStep}
          backStep={backStep}
          subscriptionType={subscriptionType}
          billingProduct={billingProduct}
          subscriptionId={subscriptionId}
          isZuoraLibraryReady={isZuoraLibraryReady}
          isTaxExempted={isTaxExempted}
        />
      </CheckoutLayout>
    </MonitoredErrorBoundary>
  );
}

const getLastStep = ({
  isTaxExempted,
  orderPreviewError,
}: {
  isTaxExempted: boolean;
  orderPreviewError: OrderPreviewError | null;
}) => {
  const contactSalesReason = (() => {
    if (isTaxExempted) {
      return CheckoutContactSalesReasons.TaxExemption;
    }
    if (!!orderPreviewError && !orderPreviewError.canRetry) {
      return CheckoutContactSalesReasons.CheckoutError;
    }
    return false;
  })();

  if (contactSalesReason) {
    return { step: CheckoutStepNames.ContactSales, reason: contactSalesReason };
  }

  return { step: CheckoutStepNames.Payment };
};

function assertSelectedPurchasingAs(val: PurchasingAs): asserts val is "company" | "individual" {
  if (val != "company" && val != "individual") {
    throw new TypeError("Invalid purchasingAs option");
  }
}

const LastStep = ({
  billingProduct,
  planConfigData,
  personalDetailsStepData,
  orderSummary,
  backStep,
  onOrderComplete,
  isZuoraLibraryReady,
  step,
  hasOrderPreviewError,
}: {
  billingProduct: ProductType;
  planConfigData?: CheckoutPlanConfigurationInputs;
  personalDetailsStepData: CheckoutPersonalDetailsInputs;
  orderSummary: React.ReactElement;
  backStep: () => void;
  onOrderComplete: () => void;
  isZuoraLibraryReady: boolean;
  hasOrderPreviewError: boolean;
  step:
    | {
        step: typeof CheckoutStepNames.ContactSales;
        reason: keyof typeof CheckoutContactSalesReasons;
      }
    | {
        step: typeof CheckoutStepNames.Payment;
      };
}) => {
  assertSelectedPurchasingAs(personalDetailsStepData.purchasingAs);

  if (step.step === CheckoutStepNames.ContactSales) {
    return (
      <CheckoutContactSalesStep
        personalDetailsStepData={personalDetailsStepData}
        billingProduct={billingProduct}
        orderSummary={orderSummary}
        onBack={backStep}
        reason={step.reason}
      />
    );
  }

  return (
    <CheckoutPaymentStep
      orderSummary={orderSummary}
      personalStepDetails={personalDetailsStepData}
      billingProduct={billingProduct}
      planConfigData={planConfigData}
      onOrderComplete={onOrderComplete}
      onBack={backStep}
      hasOrderPreviewError={hasOrderPreviewError}
      isZuoraLibraryReady={isZuoraLibraryReady}
    />
  );
};

const Steps = ({
  checkoutSteps,
  currentStep,
  advanceStep,
  backStep,
  orderSummary,
  orderPreview,
  orderPreviewError,
  subscriptionId,
  subscriptionType,
  billingProduct,
  onSubmitPlanConfiguration,
  onPlanConfigurationChanged,
  onSubmitPersonalDetails,
  onAddressChanged,
  onPurchasingAsChanged,
  planConfigurationData,
  personalDetailsStepData,
  isZuoraLibraryReady,
  isTaxExempted,
}: {
  currentStep: number;
  advanceStep: () => void;
  backStep: () => void;
  checkoutSteps: CheckoutStep[];
  onSubmitPlanConfiguration: (data: CheckoutPlanConfigurationInputs) => void;
  onPlanConfigurationChanged: (data: CheckoutPlanConfigurationInputs) => void;
  onSubmitPersonalDetails: (data: CheckoutPersonalDetailsInputs) => void;
  onAddressChanged: (data: CheckoutPersonalDetailsInputs) => void;
  onPurchasingAsChanged: (data: CheckoutPersonalDetailsInputs) => void;
  planConfigurationData?: CheckoutPlanConfigurationInputs;
  personalDetailsStepData: CheckoutPersonalDetailsInputs;
  orderSummary: React.ReactElement;
  orderPreview: OrderPreview | null;
  orderPreviewError: OrderPreviewError | null;
  subscriptionId: string;
  subscriptionType: SubscriptionType;
  billingProduct: ProductType;
  isZuoraLibraryReady: boolean;
  isTaxExempted: boolean;
}) => {
  const navigate = useNavigate();

  const onOrderComplete = () => {
    advanceStep();
  };

  const lastStep = getLastStep({ isTaxExempted, orderPreviewError });

  const configStepElement = planConfigurationData && (
    <CheckoutPlanConfigurationStep
      initialData={planConfigurationData}
      subscriptionType={subscriptionType}
      orderSummary={orderSummary}
      orderPreview={orderPreview}
      onSubmit={onSubmitPlanConfiguration}
      onChanged={onPlanConfigurationChanged}
      onBack={() =>
        navigate({
          pathname: getChangePlanRoute(subscriptionId, subscriptionType),
        })
      }
    />
  );

  const personalDetailsStepElement = (
    <CheckoutPersonalDetailsStep
      sx={{
        display:
          (currentStep === 1 && checkoutSteps[0]?.name === CheckoutStepNames.PersonalDetails) ||
          (currentStep === 2 && checkoutSteps[1]?.name === CheckoutStepNames.PersonalDetails)
            ? "flex"
            : "none",
      }}
      orderSummary={orderSummary}
      onSubmit={onSubmitPersonalDetails}
      onAddressChanged={onAddressChanged}
      onPurchasingAsChanged={onPurchasingAsChanged}
      orderPreviewError={orderPreviewError}
      initialData={personalDetailsStepData}
      orderPreview={orderPreview}
      onBack={() =>
        currentStep === 1
          ? navigate({
              pathname: getChangePlanRoute(subscriptionId, subscriptionType),
            })
          : backStep()
      }
      nextStep={lastStep.step}
    />
  );

  const lastStepElement = (
    <LastStep
      orderSummary={orderSummary}
      personalDetailsStepData={personalDetailsStepData}
      billingProduct={billingProduct}
      planConfigData={planConfigurationData}
      backStep={backStep}
      onOrderComplete={onOrderComplete}
      isZuoraLibraryReady={isZuoraLibraryReady}
      step={lastStep}
      hasOrderPreviewError={!!orderPreviewError}
    />
  );

  const checkoutCompleteElement = (
    <CheckoutComplete subscriptionId={subscriptionId} subscriptionType={subscriptionType} />
  );

  switch (currentStep) {
    case 1:
      return checkoutSteps[0]?.name === CheckoutStepNames.PlanConfiguration
        ? configStepElement
        : personalDetailsStepElement;
    case 2:
      return checkoutSteps[1]?.name === CheckoutStepNames.PersonalDetails
        ? personalDetailsStepElement
        : lastStepElement;
    case 3:
      return checkoutSteps.length === 2 ? checkoutCompleteElement : lastStepElement;
    case 4:
      return checkoutCompleteElement;
  }
};

function getChangePlanRoute(subscriptionId: string, subscriptionType: SubscriptionType) {
  return subscriptionType === "cloud"
    ? CloudSubscriptionsRoutesMap.detail(subscriptionId).upgrade
    : ServerLicensesRoutesMap.detail(subscriptionId).upgrade;
}
