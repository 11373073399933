import React from "react";
import type { ReactElement } from "react";
import type { SxProps, Theme } from "@mui/material";
import { InputLabel, Stack, styled, Typography } from "@mui/material";

interface CheckoutFormFieldProps {
  labelText?: string;
  errorMessage?: string;
  input: ReactElement;
  sx?: SxProps<Theme>;
  className?: string;
}

const ErrorMessage = styled(Typography)`
  font-style: italic;
  color: #c95340;
  line-height: 140%;
`;

const StyledInputLabel = styled(InputLabel)`
  font-weight: 500;
  color: #2e475d;
  line-height: 140%;
`;

export function CheckoutFormField(props: CheckoutFormFieldProps) {
  const { labelText, input, errorMessage, sx, className } = props;

  return (
    <Stack spacing={1} sx={sx} className={className}>
      {labelText && <StyledInputLabel htmlFor={input.props.id}>{labelText}</StyledInputLabel>}
      {input}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Stack>
  );
}
