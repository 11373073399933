import React from "react";
import type { InputBaseComponentProps } from "@mui/material";
import { Input, InputAdornment, styled } from "@mui/material";

interface CheckoutTextAreaProps {
  id?: string;
  inputProps?: InputBaseComponentProps;
  required?: boolean;
  error?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  value?: unknown;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const TextArea = styled(Input)`
  overflow: hidden;
  box-sizing: border-box;
  border: 1px solid #7c98b4;
  border-radius: 8px;
  padding: 0;
  &.Mui-focused:not(.Mui-error) {
    border-color: #0d80d8;
  }
  &.Mui-error {
    border-color: #c95340;
  }
  textarea {
    box-sizing: border-box;
    padding: 13px 20px;
  }
  .MuiInputAdornment-positionEnd {
    padding-right: 20px;
    margin-left: 0;
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  pointer-events: none;
`;

export function CheckoutTextArea(props: CheckoutTextAreaProps) {
  const { id, required, placeholder, error, inputProps, fullWidth = true, multiline = false, value, onChange } = props;

  return (
    <TextArea
      id={id}
      inputComponent={"textarea"}
      inputProps={inputProps}
      fullWidth={fullWidth}
      placeholder={placeholder}
      disableUnderline={true}
      required={required}
      multiline={multiline}
      error={error}
      value={value}
      onChange={onChange}
    />
  );
}
