import type { DateTime } from "luxon";
import type { DistributiveOmit } from "utils/DistributiveOmit/DistributiveOmit";
import type { ValidTargetPlans } from "areas/purchasing/Checkout";
import axiosClient from "../axios-client";

const BASE_URL = "purchase";

export type ZuoraRsaSignature = {
  key: string;
  signature: string;
  success: boolean;
  tenantId: string;
  token: string;
};

export enum ProductType {
  StarterServer,
  StarterCloud,
  ProfessionalServer,
  ProfessionalCloud,
}

export enum TaxRateType {
  Percentage = "Percentage",
  FlatFee = "FlatFee",
}

export interface PricingParameters {
  projects: number;
  tenants: number;
  machines: number;
  taskCap?: number;
}

export interface OrderPreviewRequest {
  product: ProductType;
  city?: string;
  country?: string;
  county?: string;
  postalCode?: string;
  state?: string;
  pricingParameters?: PricingParameters;
}

export interface OrderPreview {
  termStartDate: DateTime;
  termEndDate: DateTime;

  // Amount including any discounts
  totalAmountExcludingTax: number;

  // Amount including any discounts and tax
  totalAmount: number;

  discountAmount: number;
  chargeDescription: string;
  chargeName: string;
  productName: string;
  currencyCode: string;
  orderItems: OrderPreviewItem[];
  taxItems: OrderPreviewTaxItem[];
}

export interface OrderPreviewTaxItem {
  amount: number;
  rate: number;
  name: string;
  rateType: TaxRateType;
}

export interface OrderPreviewItem {
  serviceStartDate: DateTime;
  serviceEndDate: DateTime;
  amountWithoutTax: number;
  taxAmount: number;
  chargeDescription: string;
  chargeName: string;
  quantity: number;
}

export interface PurchasePreviewRequestDto {
  billingProduct: ProductType;
  city?: string;
  country?: string;
  county?: string;
  postalCode?: string;
  state?: string;
  pricingParameters?: PricingParameters;
}

interface OrderPreviewDto {
  serviceStartDate: DateTime;
  serviceEndDate: DateTime;
  totalAmount: number;
  amountWithoutTax: number;
  taxAmount: number;
  productName: string;
  currencyCode: string;
  orderItems: OrderPreviewItemDto[];
  taxSummaryItems: OrderPreviewTaxationItemDto[];
}

interface OrderPreviewItemDto {
  serviceStartDate: DateTime;
  serviceEndDate: DateTime;
  amountWithoutTax: number;
  taxAmount: number;
  chargeDescription: string;
  chargeName: string;
  chargeLineTaxItems: OrderPreviewTaxationItemDto[];
  quantity: number;
}

interface OrderPreviewTaxationItemDto {
  taxName: string;
  taxRate: number;
  taxAmount: number;
  taxRateType: TaxRateType;
}

export interface PurchasePreviewResponseDto {
  orderPreview: OrderPreviewDto;
  error: "Unknown" | "BadRequest" | null;
}

type BillingContactAddress = {
  address1: string;
  country: string;
  city: string;
  state: string;
  postalCode: string;
};

type BillingContact = BillingContactAddress & {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
};

export type AdvancedDigitalSignature = {
  tenantId: string;
  token: string;
  timestamp: string;
  pageId: string;
  refId: string;
  signature: string;
  errorCode?: string;
  fieldPassthrough1?: string;
  fieldPassthrough2?: string;
  fieldPassthrough3?: string;
  fieldPassthrough4?: string;
  fieldPassthrough5?: string;
};

type CheckoutRequest = {
  serial: string;
  billingProduct: ProductType;
  pricingParameters?: PricingParameters;
  billToContact: BillingContact;
  advancedDigitalSignature: AdvancedDigitalSignature;
} & (
  | {
      businessAccount: true;
      companyName: string;
      taxId?: string;
    }
  | {
      businessAccount: false;
    }
);

interface CheckoutResponseDto {
  success: boolean;
}

type OrderThroughSalesRequest = DistributiveOmit<CheckoutRequest, "advancedDigitalSignature"> &
  OrderThroughSalesAdditionalFields;

type OrderThroughSalesAdditionalFields = {
  reason: string;
};

type OrderThroughSalesResponseDto = {
  success: boolean;
};

type ContactSalesPlanConfigRequestDto = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  organization?: string;
  country: string;
  message: string;
  projectQuantity?: "20" | "30" | "40" | "50" | "60" | "70" | "80" | "90" | ">120";
  taskQuantity?: "5" | "10" | "20" | ">40";
  support?: "standard" | "24/7";
  tenantQuantity?: number;
  machineQuantity?: number;
  source: "Checkout";
  targetPlan: ValidTargetPlans;
  licenseSerial?: string;
};

type ContactSalesPlanConfigResponseDto = {
  success: boolean;
};

export type GeolocationResponseDto =
  | {
      success: true;
      isoCode: string;
    }
  | {
      success: false;
      errorMessage: string;
    };

export const getOrderPreview = async (request: OrderPreviewRequest, signal: AbortSignal): Promise<OrderPreview> => {
  function mapToDto(source: OrderPreviewRequest): PurchasePreviewRequestDto {
    return {
      city: source.city,
      billingProduct: source.product,
      country: source.country,
      county: source.county,
      postalCode: source.postalCode,
      state: source.state,
      pricingParameters: source.pricingParameters,
    };
  }

  function mapToOrderPreview(source: PurchasePreviewResponseDto): OrderPreview {
    const firstOrderItem = source.orderPreview.orderItems[0];
    return {
      chargeDescription: firstOrderItem?.chargeDescription ?? "",
      totalAmountExcludingTax: source.orderPreview.amountWithoutTax,
      discountAmount: 0,
      currencyCode: source.orderPreview.currencyCode,
      chargeName: firstOrderItem?.chargeName ?? "",
      productName: source.orderPreview.productName,
      termStartDate: source.orderPreview.serviceStartDate,
      termEndDate: source.orderPreview.serviceEndDate,
      totalAmount: source.orderPreview.totalAmount,
      orderItems:
        source.orderPreview.orderItems?.map((item) => ({
          serviceStartDate: item.serviceStartDate,
          serviceEndDate: item.serviceEndDate,
          amountWithoutTax: item.amountWithoutTax,
          taxAmount: item.taxAmount,
          chargeDescription: item.chargeDescription,
          chargeName: item.chargeName,
          quantity: item.quantity,
        })) ?? [],
      taxItems:
        source.orderPreview.taxSummaryItems?.map((i) => ({
          name: i.taxName,
          rate: i.taxRate,
          amount: i.taxAmount,
          rateType: i.taxRateType,
        })) ?? [],
    };
  }

  const response = await axiosClient.post<PurchasePreviewResponseDto>(`${BASE_URL}/preview`, mapToDto(request), {
    signal,
  });
  return mapToOrderPreview(response.data);
};

export const checkout = async (request: CheckoutRequest, signal?: AbortSignal) => {
  const response = await axiosClient.post<CheckoutResponseDto>(`${BASE_URL}/checkout`, request, {
    signal,
  });
  return response.data;
};

export const getZuoraRsaSignature = async (signal?: AbortSignal) => {
  const response = await axiosClient.get<ZuoraRsaSignature>(`${BASE_URL}/rsa-signature`, {
    signal,
  });
  return response.data;
};

export const orderThroughSales = async (request: OrderThroughSalesRequest, signal?: AbortSignal) => {
  const response = await axiosClient.post<OrderThroughSalesResponseDto>(`${BASE_URL}/contact-sales`, request, {
    signal,
  });
  return response.data;
};

export const getGeolocation = async (ipAddress?: string, signal?: AbortSignal) => {
  const requestParams = ipAddress ? `?ipAddress=${ipAddress}` : "";
  const response = await axiosClient.get<GeolocationResponseDto>(`${BASE_URL}/geolocation${requestParams}`, {
    signal,
  });
  return response.data;
};

export const checkoutContactSalesRequest = async (request: ContactSalesPlanConfigRequestDto, signal?: AbortSignal) => {
  const response = await axiosClient.post<ContactSalesPlanConfigResponseDto>(
    `${BASE_URL}/contact-sales-plan-configuration`,
    request,
    {
      signal,
    }
  );
  return response.data;
};
