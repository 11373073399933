import React, { createContext, useState, useEffect, useContext } from "react";
import type { ReactNode } from "react";
import Environment from "environment";
import type { Country } from "client/api/CountriesApi";
import { listCountries } from "client/api/CountriesApi";
import { getGeolocation } from "client/api/PurchaseApi";

interface CountriesContextProps {
  countries: Country[];
  getCurrentCountry: () => { label: string; value: string } | null;
  loading: boolean;
  error: Error | null;
}

const CountriesContext = createContext<CountriesContextProps | undefined>(undefined);

interface CountriesProviderProps {
  children: ReactNode;
}

export const CountriesProvider: React.FC<CountriesProviderProps> = ({ children }) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [geolocationIsoCode, setGeolocationIsoCode] = useState<string>();

  const getCurrentCountry = () => {
    if (!geolocationIsoCode) return null;
    const country = countries.find((item) => item.twoDigitIsoCode === geolocationIsoCode) ?? undefined;
    return country ? { label: country.name, value: country.twoDigitIsoCode } : null;
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countries = await listCountries();
        setCountries(countries);
        setLoading(false);
      } catch (err) {
        setError({ name: "CountriesProvider", message: "Failed to load countries" });
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  /** get geolocation on initial render */
  useEffect(() => {
    const getLocation = async () => {
      const controller = new AbortController();
      const envClientIpAddress = Environment.clientIpAddress();
      const fetchData = async () => {
        return await getGeolocation(envClientIpAddress, controller.signal);
      };

      try {
        (async () => {
          const data = await fetchData();
          if (data.success) {
            setGeolocationIsoCode(data.isoCode);
          }
        })();
      } catch (err) {
        // it doesn't really matter if we weren't able to fetch geolocation as it's only used to prepopulate country
      }

      return () => {
        controller.abort();
      };
    };

    getLocation();
  }, []);

  return (
    <CountriesContext.Provider value={{ countries, getCurrentCountry, loading, error }}>
      {children}
    </CountriesContext.Provider>
  );
};

export const useCountries = (): CountriesContextProps => {
  const context = useContext(CountriesContext);
  if (context === undefined) {
    throw new Error("useCountries must be used within a CountriesProvider");
  }
  return context;
};
