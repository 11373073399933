import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import type { FieldValues, UseFormReturn } from "react-hook-form/dist/types";
import type { DefaultValues } from "react-hook-form/dist/types/form";
import { ErrorAlert } from "../alert/ErrorAlert";
import { LoadingSubmitButton } from "./LoadingSubmitButton";

export type SubmittableFormProps<T extends FieldValues> = {
  submitAction: (data: T, e?: React.BaseSyntheticEvent) => Promise<void> | void;
  children: (formController: UseFormReturn<T>) => React.ReactNode;
  defaultValues?: DefaultValues<T>;
  readOnly?: boolean;
  submitLabel?: string;
  onCancel?: () => void;
  cancelLabel?: string;
  alwaysEnableSubmit?: boolean;
  noValidate?: boolean;
};

export function SubmittableForm<T extends FieldValues>(props: SubmittableFormProps<T>) {
  const {
    submitAction,
    children,
    defaultValues,
    readOnly,
    submitLabel,
    onCancel,
    cancelLabel,
    alwaysEnableSubmit,
    noValidate,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<unknown>(undefined);

  const formController = useForm<T>({ mode: "onChange", defaultValues });

  const onSubmit = async (data: T, e?: React.BaseSyntheticEvent) => {
    try {
      setIsLoading(true);

      // The UnpackNestedValue type doesn't play well with Luxon's DateTime type so if we cast as the generic type everything works
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      await submitAction(data as T, e);
    } catch (ex) {
      setApiError(ex);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...formController}>
      <form onSubmit={formController.handleSubmit(onSubmit)} noValidate={noValidate}>
        <Grid container spacing={3}>
          <>
            {!!apiError && <ErrorAlert error={apiError} />}
            {children(formController)}
            {!readOnly && (
              <Grid container item xs={12} justifyContent="flex-end">
                {onCancel && (
                  <Button
                    onClick={onCancel}
                    variant={"outlined"}
                    color={"primary"}
                    sx={{ mr: 2, textTransform: "none" }}
                    disableElevation
                  >
                    {cancelLabel ?? "Cancel"}
                  </Button>
                )}
                <LoadingSubmitButton
                  isLoading={isLoading}
                  isDisabled={!alwaysEnableSubmit && !formController.formState.isValid}
                  label={submitLabel}
                />
              </Grid>
            )}
          </>
        </Grid>
      </form>
    </FormProvider>
  );
}
