import React from "react";
import { Alert, AlertTitle, Box, Stack, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { fontSize } from "@octopusdeploy/design-system-tokens";
import { useFeatureFlag } from "contexts/ConfigurationProvider/ConfigurationProvider";
import { useCountries } from "contexts/CountriesProvider/CountriesProvider";
import type { OrderPreview } from "client/api/PurchaseApi";
import { LinkButton } from "components/LinkButton/LinkButton";
import { CustomErrorAlert, ErrorMessageWithTryAgainButtonOrContactSales } from "components/alert/CustomErrorAlert";
import type { DropdownAutocompleteOption } from "areas/checkout/components/CheckoutDropdownInput";
import type { OrderPreviewError } from "areas/purchasing/Checkout";
import { useCheckoutContactSalesDialog } from "areas/purchasing/components/Dialogs/CheckoutContactSalesDialog/CheckoutContactSalesDialogProvider";
import type { CheckoutContactSalesPlanConfigFields } from "../Dialogs/CheckoutContactSalesDialog/CheckoutContactSalesDialog";
import { OrderPreviewSummary } from "./OrderPreviewSummary";

interface CheckoutOrderSummaryBaseProps {
  subscriptionType: "cloud" | "selfhosted";
  isTaxExempted: boolean;
  country: DropdownAutocompleteOption | null;
  onToggleTaxExemption: () => void;
  isLoadingOrderPreview: boolean;
  isRecalculatingBasePrice: boolean;
  orderPreview: OrderPreview | null;
  errorReason: OrderPreviewError | null;
  hasMinimumTaxInfo: boolean;
}

type CheckoutOrderSummaryProps =
  | ({
      isBeyondStandardEntitlements?: false;
    } & CheckoutOrderSummaryBaseProps)
  | ({
      isBeyondStandardEntitlements: true;
      planConfig: React.RefObject<CheckoutContactSalesPlanConfigFields>;
    } & CheckoutOrderSummaryBaseProps);

const Skeleton = () => {
  return (
    <Box padding={3}>
      <Stack direction={"column"}>
        <LinearProgress />
      </Stack>
    </Box>
  );
};

export function CheckoutOrderSummary(props: CheckoutOrderSummaryProps) {
  const {
    orderPreview,
    isTaxExempted,
    onToggleTaxExemption,
    errorReason,
    isLoadingOrderPreview,
    isRecalculatingBasePrice,
    hasMinimumTaxInfo,
    isBeyondStandardEntitlements = false,
  } = props;

  const useZuoraProfessionalCheckout = useFeatureFlag("ZuoraProfessionalCheckout");
  const planConfig = props.isBeyondStandardEntitlements ? props.planConfig : null;

  const { showDialog } = useCheckoutContactSalesDialog();

  const { getCurrentCountry } = useCountries();

  const showContactSalesDialog = () => showDialog({ planConfig, country: props.country ?? getCurrentCountry() });

  if (errorReason && !orderPreview) {
    return <CustomErrorAlert message={<ErrorMessageWithTryAgainButtonOrContactSales retry={errorReason.retry} />} />;
  }

  if (useZuoraProfessionalCheckout && isBeyondStandardEntitlements) {
    return (
      <>
        {/* TODO: When possible, swap this Alert for the Callout component from the design system */}
        <Alert severity="info" icon={false} sx={{ margin: "24px" }}>
          <AlertTitle sx={{ fontSize: fontSize.medium }}>Looks like you want more out of your subscription</AlertTitle>
          <Typography variant="body2">Your selection is beyond what we can offer for self-service.</Typography>
        </Alert>
        <Typography variant="body2" margin={3}>
          Please <LinkButton onClick={showContactSalesDialog}>contact Sales</LinkButton> to help get your configuration
          and plan just right.
        </Typography>
        <Typography variant="body2" margin={3}>
          Or, you can adjust your selection to continue with your purchase.
        </Typography>
        <Box textAlign={"center"}>
          <LinkButton onClick={showContactSalesDialog}>Contact Sales</LinkButton>
        </Box>
      </>
    );
  }

  if (orderPreview) {
    return (
      <OrderPreviewSummary
        orderPreview={orderPreview}
        isTaxExempted={isTaxExempted}
        onToggleTaxExemption={onToggleTaxExemption}
        isLoadingOrderPreview={isLoadingOrderPreview}
        isRecalculatingBasePrice={isRecalculatingBasePrice}
        hasMinimumTaxInfo={hasMinimumTaxInfo}
        errorReason={errorReason}
        showContactSalesDialog={showContactSalesDialog}
      />
    );
  }

  return <Skeleton />;
}
