import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { fontSize, text } from "@octopusdeploy/design-system-tokens";
import { useCountries } from "contexts/CountriesProvider/CountriesProvider";
import { checkoutContactSalesRequest } from "client/api/PurchaseApi";
import { ErrorAlert } from "components/alert/ErrorAlert";
import { SubmittableForm } from "components/form/SubmittableForm";
import type { DropdownAutocompleteOption } from "areas/checkout/components/CheckoutDropdownInput";
import { CheckoutDropdownInput } from "areas/checkout/components/CheckoutDropdownInput";
import { CheckoutFormField } from "areas/checkout/components/CheckoutFormField";
import { CheckoutTextArea } from "areas/checkout/components/CheckoutTextArea";
import { CheckoutTextInput } from "areas/checkout/components/CheckoutTextInput";
import type { ValidTargetPlans } from "areas/purchasing/Checkout";
import type { DialogProps } from "../../../../../components/Dialogs/DialogProps";

//we want a custom onClose
type CheckoutContactSalesModalProps = Omit<DialogProps, "onClose"> & {
  onClose: (data?: CheckoutContactSalesFields) => void;
  defaults?: Partial<CheckoutContactSalesFields>;
  source: "Checkout";
  serial?: string;
};

type TargetPlan = ValidTargetPlans;

export type CheckoutContactSalesPlanConfigFields = {
  projectQuantity?: "20" | "30" | "40" | "50" | "60" | "70" | "80" | "90" | ">120";
  taskQuantity?: "5" | "10" | "20" | ">40";
  support?: "standard" | "24/7";
  tenantQuantity?: number;
  machineQuantity?: number;
};

type CheckoutContactSalesFormFields = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  organization?: string;
  country: DropdownAutocompleteOption | null;
  message: string;
};

export type CheckoutContactSalesFields = {
  targetPlan: TargetPlan;
  planConfig: React.RefObject<CheckoutContactSalesPlanConfigFields> | null;
} & CheckoutContactSalesFormFields;

const StyledCheckoutFormField = styled(CheckoutFormField)`
  label {
    font-size: ${fontSize.medium};
  }
`;

export function CheckoutContactSalesDialog(props: CheckoutContactSalesModalProps) {
  const { open, onClose, defaults, source, serial } = props;
  const { countries } = useCountries();

  const [error, setError] = useState<Error | undefined>();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const dialogContentEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // reset the modal after closing it
    if (!open && showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 100);
    }
  }, [open, showSuccessMessage]);

  const contactSales = async (fields: CheckoutContactSalesFields) => {
    const mappedData = {
      ...fields,
      country: fields.country?.value || "", // map the country over
      planConfig: undefined, // clear out the nested property spread from fields because we spread it from the ref in to the root of the object instead
      ...fields.planConfig?.current,
    };
    try {
      await checkoutContactSalesRequest({
        ...mappedData,
        source,
        licenseSerial: serial,
      });

      setShowSuccessMessage(true);
    } catch (ex) {
      if (ex instanceof Error) {
        setError(ex);
        dialogContentEl.current && dialogContentEl.current.scrollTo({ top: 0 });
      }
    }
  };

  if (showSuccessMessage) {
    return (
      <Dialog open={open} onClose={() => onClose()} maxWidth={"sm"} fullWidth>
        <DialogContent>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant={"h2"} sx={{ margin: 3, font: text.interface.heading.large }}>
              Thank you
            </Typography>
            <Typography sx={{ margin: 3 }}>We'll be in touch soon</Typography>
            <Button
              variant="contained"
              onClick={() => onClose()}
              sx={{ textTransform: "none" }}
              disableElevation
              disableRipple
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth={"sm"} fullWidth>
      <DialogTitle>
        Contact Sales
        {onClose && (
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent ref={dialogContentEl}>
        {error && (
          <Box sx={{ mb: 2 }}>
            <ErrorAlert error={error} />
          </Box>
        )}
        <Alert severity="info" sx={{ mb: 2 }}>
          <AlertTitle sx={{ fontSize: fontSize.medium }}>Let's get your plan just right</AlertTitle>
          <Typography variant="body2">
            So we can help, please let us know the best contact and any other useful information.
          </Typography>
        </Alert>

        <SubmittableForm
          submitAction={contactSales}
          onCancel={() => onClose()}
          cancelLabel="Close"
          defaultValues={defaults}
          alwaysEnableSubmit
          submitLabel="Send"
          noValidate
        >
          {(formController) => {
            const { register, formState, watch, setValue } = formController;
            return (
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <StyledCheckoutFormField
                    labelText="First name"
                    errorMessage={formState.errors?.firstName?.message}
                    input={
                      <CheckoutTextInput
                        id={"contactSalesDialog.firstName"}
                        required={true}
                        error={!!formState.errors?.firstName}
                        inputProps={{
                          ...register("firstName", {
                            required: "Please enter a first name",
                          }),
                          autoComplete: "given-name",
                        }}
                      />
                    }
                  />

                  <StyledCheckoutFormField
                    labelText="Last name"
                    errorMessage={formState.errors?.lastName?.message}
                    input={
                      <CheckoutTextInput
                        id={"contactSalesDialog.lastName"}
                        required={true}
                        error={!!formState.errors?.lastName}
                        inputProps={{
                          ...register("lastName", {
                            required: "Please enter a last name",
                          }),
                          autoComplete: "family-name",
                        }}
                      />
                    }
                  />

                  <StyledCheckoutFormField
                    labelText="Work email"
                    errorMessage={formState.errors?.email?.message}
                    input={
                      <CheckoutTextInput
                        id={"contactSalesDialog.email"}
                        required={true}
                        error={!!formState.errors?.email}
                        inputProps={{
                          ...register("email", {
                            required: "Please enter an email address",
                          }),
                          type: "email",
                          autoComplete: "work email",
                        }}
                      />
                    }
                  />

                  <StyledCheckoutFormField
                    labelText="Phone"
                    errorMessage={formState.errors?.phone?.message}
                    input={
                      <CheckoutTextInput
                        id={"contactSalesDialog.phone"}
                        required={true}
                        error={!!formState.errors?.phone}
                        inputProps={{
                          ...register("phone", {
                            required: "Please enter a phone number",
                          }),
                          type: "tel",
                          autoComplete: "work tel",
                        }}
                      />
                    }
                  />

                  <StyledCheckoutFormField
                    labelText="Organization"
                    errorMessage={formState.errors?.organization?.message}
                    input={
                      <CheckoutTextInput
                        id={"contactSalesDialog.organization"}
                        error={!!formState.errors?.organization}
                        inputProps={{
                          ...register("organization", {}),
                          autoComplete: "organization",
                        }}
                      />
                    }
                  />

                  <StyledCheckoutFormField
                    labelText="Country"
                    errorMessage={formState.errors?.country?.message}
                    input={
                      <CheckoutDropdownInput
                        id="contactSalesDialog.country"
                        required={true}
                        options={countries?.map((country) => ({
                          label: country.name,
                          value: country.twoDigitIsoCode,
                        }))}
                        value={watch("country")}
                        setOption={(value) => {
                          setValue("country", value ?? null);
                        }}
                        isOptionEqualToValue="valueMatch"
                        error={!!formState.errors.country}
                        placeholder="Please select a country"
                        InputProps={{
                          ...register("country", {
                            required: {
                              value: true,
                              message: "Please select a country",
                            },
                          }),
                        }}
                      />
                    }
                  />

                  <StyledCheckoutFormField
                    labelText="What would you like to discuss with Octopus?"
                    errorMessage={formState.errors?.message?.message}
                    input={
                      <CheckoutTextArea
                        id={"contactSalesDialog.message"}
                        fullWidth
                        multiline
                        error={!!formState.errors?.message}
                        inputProps={{
                          ...register("message", {
                            required: {
                              value: true,
                              message: "Please enter a message",
                            },
                          }),
                          rows: 4,
                        }}
                      />
                    }
                  />
                </Stack>
              </Grid>
            );
          }}
        </SubmittableForm>
      </DialogContent>
    </Dialog>
  );
}
